.auth_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.auth_innerContaienr {
  width: 990px;
  height: 510px;
  background: #ffffff;
  box-shadow: 0px 0px 4px #dcdcdc;
  border-radius: 24px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.auth_innerContaienr_leftCol,
.auth_innerContaienr_rightCol {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.auth_innerContaienr_leftCol {
  justify-content: flex-end;
}
.auth_innerContaienr_rightCol {
  box-shadow: 0px 0px 4px #dcdcdc;
  align-items: center;
  padding-top: 55px;
}

@media screen and (max-width: 1079px) {
  .auth_innerContaienr {
    width: calc(100% - 35px);
  }
}

@media screen and (max-width: 767px) {
  .auth_innerContaienr_leftCol {
    display: none;
  }
  .auth_innerContaienr_rightCol {
    box-shadow: none;
    width: 100%;
  }
}
