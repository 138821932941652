.table_table_header {
  margin-bottom: 20px;
}
.items_per_page_select {
  box-shadow: 0px 0px 4px rgba(119, 119, 119, 0.13);
  border-radius: 4px;
  width: 56.32px;
  height: 28.16px;
  color: #777777;
  border: 0;
  outline: 0;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: auto;
  overflow: hidden;
  border-radius: 12px !important;
}

.table thead tr {
  border: 0;
}
.table tr {
  padding: 0.35em;
  border-bottom: 0.5px solid #dcdcdc;
}

.table th {
  background: #f5f5f5;
  padding-top: 0.8em !important;
  padding-bottom: 0.8em !important;
  position: relative;
}
.table td {
  background: #fff;
  vertical-align: top;
}

.table th,
.table td {
  padding: 20px 24px;
  text-align: left;
  max-width: 145px;
  white-space: initial;
  overflow-wrap: anywhere;
}

.table th:first-child,
.table td:first-child {
  padding-inline-start: 40px;
}

.table th {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  cursor: pointer;
}
body[dir="rtl"] .table th {
  text-align: right;
}
.table .table_data_innerContent {
  display: flex;
  align-items: center;
}
.table .status_icon {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-inline-end: 7px;
}
.table .sortableIcon {
  position: absolute;
  transform: translateX(7px);
}
body[dir="rtl"] .table .sortableIcon {
  transform: translateX(-10px);
}
.table_pagination_container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.table_pagination_page_btn_container {
  display: flex;
  flex-wrap: wrap;
}
.table_pagination_page_btn {
  width: 34px;
  height: 34px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
  margin: 0 2px;
  cursor: pointer;
  &.active {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(119, 119, 119, 0.13);
    border-radius: 4px;
  }
}
.table_prev_btn,
.table_next_btn {
  height: 34px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  padding: 0 10px;
  margin: 0 5px;
  color: var(--secondary-color);
  cursor: pointer;
}
.table_prev_btn:hover,
.table_next_btn:hover {
  color: var(--primary-bg);
}
@media screen and (max-width: 1023px) {
  .table {
    border: 0;
  }

  .table th {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .table tr {
    display: block;
    margin-bottom: 15px;
    border-radius: 12px;
    overflow: hidden;
    border: 0;
    padding: 0;
  }
  .table th,
  .table td {
    max-width: 100%;
  }
  .table th:first-child,
  .table td:first-child {
    text-align: left;
  }
  .table td:first-child {
    padding-top: 20px;
  }
  .table th:first-child,
  .table td:first-child {
    padding-inline-start: 20px;
  }
  .table th:last-child,
  .table td:last-child {
    padding-inline-end: 20px;
  }
  .table td {
    border-bottom: 0;
    display: block;
    font-size: 0.8em;
    text-align: left;
    position: relative;
    display: flex;
    padding: 10px 20px;

    align-items: flex-start;
  }
  .table .table_data_innerContent {
    width: 48%;
  }
  .table td::before {
    content: attr(data-label);
    display: inline-block;
    width: 40%;
    padding-inline-end: 7%;
    font-weight: 500;
    position: relative;
    z-index: 11;
  }
  .table td::after {
    content: "";
    background-color: #f5f5f5;
    position: absolute;
    left: 0;
    top: 0;
    width: 40%;
    height: 100%;
  }
  .table td:last-child {
    border-bottom: 0;
    padding-bottom: 20px;
  }
}
