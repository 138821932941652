@import "src/styles/variables.scss";
:root {
  --primary-bg: #000a37;
  --primary-bg-shade0: #091c4e;
  --secondary-bg: #f66928;
  --alternative-bg: #ffffff;
  --primary-color: #000a37;
  --body-color: #fafbfe;
  --secondary-color: #555555;
  --alternative-color: #ffffff;
  --sidebar-width: 258px;
  --dashboard-container-width: 258px;
  --success-color: #1eb66a;
  --error-color: #de0000;
}

@media screen and (max-width: 1439px) {
  :root {
    --sidebar-width: 100px;
    --dashboard-container-width: 100px;
  }
}
@media screen and (max-width: 1279px) {
  :root {
    --sidebar-width: 100px;
    --dashboard-container-width: 0px;
  }
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins";
  background: var(--body-color);

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 24px;
  font-weight: 700;
  color: var(--primary-color);
}

h3 {
  font-size: 24px;
  font-weight: 500;
}
h4 {
  font-size: 18px;
  font-weight: 500;
}
h6 {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-color);
}
p {
  font-size: 14px;
  font-weight: 400px;
}
select {
  font-family: "Poppins";
}
button {
  height: 44.8px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 0;
  padding: 0 20px;
  font-weight: 500;
  font-size: 18px;
  font-family: "Poppins";
  cursor: pointer;
}

.btn_primary {
  background: var(--primary-bg);
  color: var(--alternative-color);
}
.btn_secondary {
  background: var(--secondary-bg);
  color: var(--alternative-color);
}
svg {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
