.form_control {
  margin: 30px 0;
  position: relative;
}
.input_field {
  width: 100%;
  height: 46px;
  background-color: #efefef;
  border-radius: 10px;
  border: 0;
  outline: 0;
  color: #555555;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 0 24px 0 34px;
  padding-inline-start: 34px;
  padding-inline-end: 24px;

  box-sizing: border-box;
  position: relative;
}
.input_field_icon {
  position: absolute;
  z-index: 11;
  top: 11px;
  left: 12px;
  // transform: translateY(-50%);
}
body[dir="rtl"] .input_field_icon {
  right: 12px;
  left: auto;
}

.input_checkbox {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.checkbox_label {
  position: relative;
  cursor: pointer;
}
.checkbox_label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #777777;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  //   padding: 10px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-inline-end: 8px;
}
.input_checkbox:checked + .checkbox_label::before {
  content: "";
  background-color: #f66928;
  border: 1px solid #f66928;
}
.input_checkbox:checked + .checkbox_label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 6px;
  width: 4px;
  height: 11px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
body[dir="rtl"] .input_checkbox:checked + .checkbox_label:after {
  right: 6px;
  left: auto;
}
.form_input_error {
  color: #f05248;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
  left: 5px;
}
