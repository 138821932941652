@import "src/styles/variables.scss";

.dashboard_container {
  //   transform: translateX(var(--sidebar-width));
  //   margin: 0 auto;
  padding-inline-start: calc(var(--dashboard-container-width) + 27px);
  transition: 0.3s;
  padding-inline-end: 27px;
  margin-bottom: 30px;
  position: relative;
  z-index: 0;
}
.main_dashboard {
  padding-top: 100px;
  min-height: 80vh;
}

@media screen and (max-width: 767px) {
  .dashboard_container {
    padding: 0 15px;
  }
}
