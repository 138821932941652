@import "src/styles/variables.scss";

.navbar {
  position: absolute;
  width: 100%;
  height: 74px;
  background: var(--alternative-bg);
  box-shadow: 0px 0px 4px #dcdcdc;
}
.navbar_container {
  height: 100%;
}
.navbar_wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.navbar_wrapper_leftCol {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ham_icon {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.navbar_logo_sm {
  display: none;
}
.navbar_wrapper_rightCol {
  display: flex;
  justify-items: flex-end;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
  }
}
// .navbar_wrapper_rightCol_icon_wrapper {
//   display: flex;
//   align-items: center;
// }
.navbar_img_wrapper {
  margin: 0 8px;
  display: flex;
  align-items: center;
}
.navbar_splitter {
  height: 32px;
  width: 1px;
  background: #dfe0eb;
  margin: 0 12px;
}
.navbar_user_details {
  text-align: center;
}
.navbar_user_data {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.navbar_user_data_name,
.navbar_user_data_role {
  text-align: center;
}
.navbar_user_data_role {
  font-size: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: right;
  letter-spacing: 0.2px;

  color: #9fa2b4;
}
.user_image {
  background: var(--primary-bg);
  width: 42px;
  height: 42px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-start: 16px;
}

@media screen and (max-width: 1279px) {
  .navbar_logo_sm {
    display: block;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-inline-start: 28px;
  }
}

@media screen and (max-width: 767px) {
  .navbar_user_data {
    display: none;
  }
  .navbar_splitter {
    display: none !important;
  }
  .user_image {
    width: 28px;
    height: 28px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
