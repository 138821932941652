.cards_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 5px;
}
.card {
  width: 31.9%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  min-height: 113px;
  box-sizing: border-box;
  padding: 16px 34px;
  margin: 6px 0;
}
.card_container {
  display: flex;
  align-items: center;
}
.card_icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-inline-end: 16px;

  img {
    width: 65%;
  }
}
.card_content {
  // display: flex;
  &_title {
    font-weight: 400;
    font-size: 14px;
  }
  &_cost {
    font-weight: 500;
    font-size: 24px;
  }
}
.card_time {
  font-weight: 400;
  font-size: 14px;
}

@media screen and (max-width: 1279px) {
  .card_time {
    font-weight: 400;
    font-size: 12px;
  }
}

@media screen and (max-width: 1023px) {
  .cards_container {
    flex-direction: column;
    margin-top: 0;
  }
  .card {
    width: 100%;
    padding: 16px 20px;
  }
}
