.dashboard_chart_container {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1023px) {
  .dashboard_chart_container {
    flex-direction: column;
  }
}
