.navigation_container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.navigate_link {
  color: #777777;
  font-weight: 400;
  font-size: 18px;
  text-decoration: none;
  margin-inline-start: 23px;
  margin-inline-end: 23px;

  position: relative;
  text-transform: lowercase;

  cursor: default;
  &:first-child {
    margin-inline-start: 0;
  }

  &.clickable {
    color: #555555;
    cursor: pointer;
    text-transform: capitalize;
  }
}
