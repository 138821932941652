.spinner_container {
  display: flex;
  justify-content: center;
}
.loading_spinner {
  width: 20px;
  height: 20px;
  position: relative;
  margin: 0 auto;
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;
  border-top-color: transparent !important;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
