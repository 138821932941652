.login_formContainer {
  width: 354px;
}
.login_form_control {
  text-align: center;
}
.login_btn {
  min-width: 162px;
  height: 46px;
  border-radius: 23px;
}
.login_link {
  color: var(--secondary-bg);
}
.forgot_password_link {
  color: #555555;
}
.unauth_error_container {
  position: relative;
  & > div {
    position: relative;
    text-align: center;
    bottom: -15px;
    width: 100%;
  }
}
@media screen and (max-width: 1023px) {
  .login_formContainer {
    width: calc(100% - 50px);
  }
}
@media screen and (max-width: 767px) {
  .login_formContainer {
    width: calc(100% - 35px);
  }
}
