.popup {
  position: fixed;
  z-index: 555555;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content */
.popup_content {
  overflow-x: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup_content_container {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  margin-bottom: 40px;
  background-color: #fefefe;
  border-radius: 15px;
}

/* The Close Button */
.popup_close {
  color: rgba(255, 255, 255, 0.5);
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 22px;
  transition: 0.2s ease-in-out;
  z-index: 999;
}

.popup_close:hover,
.popup_close:focus {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  cursor: pointer;
}
