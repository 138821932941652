@import "src/styles/variables.scss";

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: var(--sidebar-width);
  background: var(--primary-bg);
  height: 100vh;
  padding-top: 42px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  transition: 0.3s;
  z-index: 11;
}
body[dir="rtl"] .sidebar {
  right: 0;

  .sidebar_link {
    border-radius: 16px 0 0 16px;
  }
  .sidebar_link_dropdown_content_item::before {
    right: -20px;
  }
  .sidebar_link_dropdown_content_item::after {
    right: -15px;
  }
}
body[dir="rtl"] .sidebar .sidebar_link_dropdown_sm_container {
  left: auto !important;
  right: var(--sidebar-width) !important;
  border-radius: 12px 0 0 12px !important;
}
.close_side_bar {
  position: absolute;
  right: 18px;
  top: 5px;
  color: white;

  font-size: 2em;
}
.sidebar_brand_logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar_links {
  padding-top: 85px;
}

.sidebar_link {
  color: var(--alternative-color);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-inline-start: 24px;
  box-sizing: border-box;
  width: calc(93%);
  border-radius: 0px 16px 16px 0px;
  height: 55px;
  position: relative;
  font-weight: 500;
  font-size: 22px;
  margin: 5px 0;
  .sidebar_link_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-inline-end: 10px;
    &_svg {
      width: 100%;
    }
  }
  &:hover {
    background: var(--primary-bg-shade0);
    cursor: pointer;
  }
  &.active {
    background: var(--primary-bg-shade0);
    position: relative;
    z-index: 1;
  }
}
@keyframes close_dropdown {
  100% {
    transform: scaleY(0);
  }
}
@keyframes open_dropdown {
  100% {
    transform: scaleY(1);
  }
}
.sidebar_link_dropdown {
  &_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &_content {
    color: white;
    margin-inline-start: 50px;
    display: flex;
    flex-direction: column;
    transform: scaleY(0);
    animation: open_dropdown 0.3s forwards;
    &_item {
      position: relative;
      margin: 7px 0;
      color: #70768f;
      text-decoration: none;
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 40px;
        background: #70768f;
        border-radius: 100%;
        left: -15px;
        bottom: 7px;
        z-index: -11;
      }
      &::before {
        content: "";
        position: absolute;
        width: 11px;
        height: 11px;
        background: #70768f;
        border-radius: 100%;
        top: 50%;
        left: -20px;
        transform: translateY(-50%);
      }
      &.active {
        color: #fff;
        &::after {
          background: #fff;
        }
        &::before {
          background: #fff;
        }
      }
    }
  }
  &_chevron {
    margin-inline-end: 20px;
    transition: 0.3s;
  }
}

.sidebar.sidebar_toggle {
  .sidebar_link {
    justify-content: center;
    padding-inline-start: 0;
    width: 100%;
    border-radius: 0;
    .sidebar_link_icon {
      margin-inline-end: 0;
    }
  }
  .sidebar_link:hover + .sidebar_link_dropdown_sm_container {
    display: block;
  }
  .sidebar_link_dropdown {
    position: relative;
  }
  .sidebar_link_dropdown_title {
    background: var(--primary-bg-shade0);
    height: 55px;
    padding: 0 19px;
    position: relative;
    z-index: 1;
  }
  .sidebar_link_dropdown_sm_container {
    display: none;
    position: absolute;
    background: var(--primary-bg);
    top: 0;
    left: var(--sidebar-width);
    border-radius: 0 12px 12px 0;
    overflow: hidden;
    z-index: 11;
    &:hover {
      display: block;
    }
  }
  .sidebar_link_dropdown_content {
    margin-inline-start: 16px;
    padding: 0 19px;
  }
}

@media screen and (max-width: 1279px) {
  .sidebar {
    display: none;
    width: 0;
    animation: open-sidebar-sm 0.3s forwards;
  }
  @keyframes open-sidebar-sm {
    100% {
      width: var(--sidebar-width);
    }
  }
}
