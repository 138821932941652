.pageNotFound_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;
  //   flex-direction: column;
}
.pageNotFound_innerContainer {
  width: clamp(300px, 50%, 344px);

  img {
    width: calc(85%);
  }
}
.pageNotFound_404_text {
  font-weight: 600;
  font-size: 48px;

  letter-spacing: 0.3px;

  color: #555555;
}
.pageNotFound_title_text {
  color: #555555;
  margin-bottom: 10px;
}
.pageNotFound_description {
  font-weight: 400;
  font-size: 16px;

  text-align: center;
  letter-spacing: 0.3px;

  color: #555555;
}
