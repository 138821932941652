.area_chart_container {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: 12px;
  width: 48.9%;

  box-sizing: border-box;
  padding: 22px 24px;
}
.chart_header {
  display: flex;
  justify-content: space-between;
}
.chart_header_dropdown {
  position: relative;
}
.chart_header_dropdown_title,
.chart_header_dropdown_content_item {
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.chart_header_dropdown_title {
  background: #efefef;
  min-width: 103px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.chart_header_dropdown_content {
  position: absolute;
  right: 0;
  top: 40px;
  border-radius: 4px;
  background: #efefef;
  width: 85%;
  overflow: hidden;
  z-index: 111;
}
.chart_header_dropdown_content_item {
  cursor: pointer;
  &.active {
    background: #dedede;
    cursor: default;
  }
}

.chart_title {
  font-weight: 400;
  font-size: 16px;
}
.chart_current_value {
  font-weight: 500;
  font-size: 24px;
}
.chart_wrapper {
  height: 18vw;
  margin-bottom: 5px;
  width: 103%;
  margin-top: 20px;
  transform: translateX(-21px);
}
body[dir="rtl"] {
  .chart_wrapper {
    transform: translateX(5px);
  }
}

@media screen and (max-width: 1023px) {
  .area_chart_container {
    width: 100%;
  }
  .chart_wrapper {
    width: calc(100% + 55px);
    transform: translateX(-33px);
    height: 74vw;
  }
}
